<template>
    <slot :target="(el) => target = el" :open="open" :isOpen="isOpen" v-bind="$attrs"></slot>
    <template v-if="isOpen">
        
        <teleport :to="teleportTargetOverride ?? teleportTarget">
            <slot name="dropdown" :container="setContainerRef" :close="close"></slot>
        </teleport>
    </template>
</template>

<script setup lang="ts">
import { dataGridControlKey, nestedStackKey } from 'o365.modules.vue.injectionKeys.js';
import { ref, onBeforeMount, onMounted, onBeforeUnmount, inject, nextTick } from 'vue';
import useNestedStack from 'o365.vue.composables.NestedStack.ts';
import useTarget from 'o365.vue.composables.target.ts';
import { createPopper } from 'popper';

const props = defineProps({
    onBeforeOpen: Function,
    onBeforeClose: Function,
    placement: {
        type: String,
        default: 'bottom-start',
    },
    popperOptions: {
        type: Array,
        default: () => [],
    },
    popperStrategy: {
        type: String,
        default: 'absolute'
    },
    targetRef: null,
    virtual: Boolean,
    name: String,
    data: {},
    teleportTargetOverride: null,
});

if (props.name) {
    useTarget({
        name: props.name,
        handler: (targetEl, e) => {
            target.value = targetEl;
            if (isOpen.value) {
                e.stopPropagation();
                popperInstance.value.state.elements.reference = target.value
                popperInstance.value.update();
            } else {
                open();
            }
        }
    });
}

const emit = defineEmits(['onbeforeopen', 'onbeforeclose', 'onopen', 'beforeopen', 'beforeclose', 'open']);

const masterGrid = inject(dataGridControlKey, null);

//--- Nested dropdown logic ---
const { currentNest, add: addToNest, remove: removeFromNest } = useNestedStack({
    injectinoKey: nestedStackKey,
});

//--- DATA ---
const isOpen = ref(false);
const teleportTarget = ref('#o-dropdown-global-container');
const containerZIndex = ref('auto');
const popperStrategy = ref(props.popperStrategy);

//--- DOM REFERANCES ---
const target = ref(props.targetRef);
const container = ref<HTMLElement|null>(null);

let shouldClose = false;
//--- FUNCTIONS ---
function onMouseDown(e: MouseEvent) {
    shouldClose = true;
    const composedPath = e.composedPath();
    currentNest.forEach((dropdown) => {
        if (composedPath.indexOf(dropdown) !== -1) { shouldClose = false; }
    });
}

function onMouseUp(e: MouseEvent) {
    if (isOpen.value && shouldClose) {
        close();
    }
    shouldClose = false;
}

function onClickOutside(e) {
    const composedPath = e.composedPath();
    let shouldClose = true;

    currentNest.forEach((dropdown) => {
        if (composedPath.indexOf(dropdown) !== -1) { shouldClose = false; }
    });

    if (shouldClose) { close(); }
};

function onKeydown(e) {
    if (!isOpen.value) { return; }

    if (typeof masterGrid?.value?.handleDropdownKeydown === 'function') {
        masterGrid.value.handleDropdownKeydown(e, target);
    }
}

function onEscape(e) {
    if (e.key === 'Escape') {
        isOpen.value = false;
        close();
    }
}

const popperInstance = ref(null);
async function open() {
    if (isOpen.value) { return; }
    if (props.onBeforeOpen) { 
        props.onBeforeOpen(); 
    } else { 
        emit('onbeforeopen'); 
        emit('beforeopen');
    }

    isOpen.value = true;
    await nextTick();

    if (!container.value.style.zIndex) {
        container.value.style.zIndex = containerZIndex.value;
    }
    if (container.value) {
        container.value.dataset.gridSkipClickHandler = 'true';
    }

    addToNest(container.value); 
    
   popperInstance.value = createPopper(props.virtual ? props.targetRef : target.value, container.value, {
        placement: props.placement,
        strategy: popperStrategy.value,
        modifiers: [
            {
                name: 'flip',
                enabled: true
            },
            ...props.popperOptions
        ],
    });

    window.requestAnimationFrame(() => {
        // document.addEventListener("click", onClickOutside);
        document.addEventListener('mousedown', onMouseDown);
        document.addEventListener('mouseup', onMouseUp);
        container.value?.addEventListener("keydown", onKeydown);
        document.addEventListener("keydown", onEscape);
        emit('onopen');
        emit('open');
    });
}

function close() {
    if (!isOpen.value) { return Promise.resolve(); }
    return new Promise((res) => {
        if (props.onBeforeClose) { 
            props.onBeforeClose(); 
        } else { 
            emit('onbeforeclose'); 
            emit('beforeclose'); 
        }
        document.removeEventListener('mousedown', onMouseDown);
        document.removeEventListener('mouseup', onMouseUp);
        // document.removeEventListener("click", onClickOutside);
        container.value?.removeEventListener("keydown", onKeydown);
        document.removeEventListener("keydown", onEscape);

        //removeFromNest();

        popperInstance.value?.destroy();
        popperInstance.value = null;

        window.requestAnimationFrame(() => {
            removeFromNest();
            isOpen.value = false;
            shouldClose = false;
            res();
        })
    });
}

onBeforeMount(() => {
    if (!document.getElementById('o-dropdown-global-container')) {
        const dropdownsContainer = document.createElement('div');
        dropdownsContainer.id = 'o-dropdown-global-container';
        document.body.append(dropdownsContainer);
    }
});

onMounted(() => {
    if (target.value && !props.virtual) {
        const modal = target.value?.closest('.modal-content') ?? target.value?.closest('.modal');
        if (modal) {
            teleportTarget.value = modal;
            popperStrategy.value = 'fixed';
        }

        const getZIndex = (node) => {
            const zIndex = window.getComputedStyle(node).zIndex;
            if (zIndex && zIndex !== 'auto') { containerZIndex.value = parseInt(zIndex) + 1 }
            if (node.parentElement) { getZIndex(node.parentElement); }
        }

        getZIndex(target.value);

    }
});

onBeforeUnmount(() => {
    if (isOpen.value) {
        emit('onbeforeclose');
    }
    document.removeEventListener("keydown", onEscape);
});

function setContainerRef(el) {
    container.value = el;
    if(!masterGrid){
        return;
    }

    if (el && masterGrid?.value?.id) {
        el.dataset.gridMasterId = masterGrid.value.id;
    } 
}


defineExpose({ target, container, isOpen, open, close, teleportTarget, popperInstance });
</script>
<script lang="ts">
export default {
    name: 'ODropdown'
}
</script>
